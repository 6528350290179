import { stateRegistry } from "microkernel";

export default class VTP extends HTMLElement {
	constructor() {
		super();
		this.dropdown = null;
		this.currentModelId = null;
		this.kvpsSyncId = undefined;
		this._onStateUpdate = this._onStateUpdate.bind(this);
		this._initalizePartnerPatch = this._initalizePartnerPatch.bind(this);
		this._initalizePartnerPatchService = this._initalizePartnerPatchService.bind(
			this
		);
		this._initalizePartnerPatchService();
	}

	connectedCallback() {
		this._initializeModule();
	}

	disconnectedCallback(){
		document.removeEventListener("PAGE_READY", this._initalizePartnerPatch);
		document.removeEventListener("LAYER_LOADED", this._initalizePartnerPatch);
	}

	/**
	 * Sets the event listeners in order to patch the VTP
	 * @returns {void}
	 */
	_initalizePartnerPatchService() {
		stateRegistry.subscribeToStore("dbadDealerStore", this._onStateUpdate);
		document.addEventListener("PAGE_READY", this._initalizePartnerPatch);
		document.addEventListener("LAYER_LOADED", this._initalizePartnerPatch);
	}

	/**
	 * Updates the state with the correct ID and initalize the patch.
	 * 
	 * @param {object} state_ 
	 */
	_onStateUpdate(state_) {
		this.kvpsSyncId = state_.kvpsSyncId || undefined;
		this._initalizePartnerPatch();
	}

	/**
	 * Filters data returned from api
	 * @returns {Array.<{id: string, count: number}>}
	 */
	_parseData(data) {
		if (data.items) {
			const items = data.items;
			const list = items.filter((dataObject) =>
				dataObject.id.search(/(carline)/) > -1 ? true : false
			);
			return list;
		}
		return undefined;
	}

	/**
	 * Merges the usedCar-Api data with the newCar Api data
	 *
	 * @param {Array.<{id: string, count:number}>} listNew
	 * @param {Array.<{id: string, count:number}>} listUsed
	 *
	 * @returns {Array.<{id: string, new: number, used: number}>}
	 */
	_mergeLists(listNew, listUsed) {
		const map = new Map();
		listNew.forEach((listObject) => {
			const usedObject = listUsed.find(
				(usedItem) => usedItem.id === listObject.id
			);
			const used = usedObject
				? usedObject.count
					? usedObject.count
					: 0
				: 0;
			map.set(listObject.id.split(".")[1], {
				new: listObject.count,
				used,
			});
		});

		listUsed.forEach((usedListObject) => {
			const id = usedListObject.id.split(".")[1];
			if (!map.has(id)) {
				map.set(id, { new: 0, used: usedListObject.count });
			}
		});

		return Array.from(map).map((value) => {
			return {
				id: value[0],
				...value[1],
			};
		});
	}

	/**
	 * Fetches the car data from the api
	 * @param {{ partnerId: string; newCarsUrl: string; usedCarsUrl: string}}
	 *
	 * @returns {Array.<{id: string, new: number, used: number}> | undefined}
	 */

	async _fetchData({ partnerId, newCarsUrl, usedCarsUrl }) {
		if (window) {
			const url = (uri) => {
				const url = new URL(window.location.protocol + uri);
				url.searchParams.append("svd", "!");
				url.searchParams.append("filter", `dealer.${partnerId}`);
				return url.href;
			};
			const usedCarsDataResponse = await fetch(url(usedCarsUrl));
			const newCarsDataResponse = await fetch(url(newCarsUrl));
			const usedCarsData = await usedCarsDataResponse.json();
			const newCarsData = await newCarsDataResponse.json();

			if (usedCarsData && newCarsData) {
				const newCars = this._parseData(newCarsData);
				const usedCars = this._parseData(usedCarsData);
				if (newCars && usedCars) {
					const allCars = this._mergeLists(newCars, usedCars);

					return allCars;
				}
				/* istanbul ignore next */
				return undefined;
			}
		}
		/* istanbul ignore next */
		return undefined;
	}

	/**
	 * Patches the VTP links and numbers
	 *
	 * @param {Array.<{id: string, new: number, used: number}>} carList
	 * @return {void}
	 */
	_patch(carList) {
		const allCarsInElement = this.querySelectorAll(
			".nm-vtp__dropdown-links-container [data-modelid]"
		);

		if (allCarsInElement) {
			allCarsInElement.forEach((element) => {
				const id = element.dataset.modelid;
				const inPatchList = carList.find((car) => car.id === id);
				const newHref = element.querySelector(".nm-vtp__link-new-cars");
				const usedHref = element.querySelector(
					".nm-vtp__link-used-cars"
				);

				if (newHref) {
					const newCount = newHref.querySelector(".nm-vtp__link-count");
					newCount.innerHTML = inPatchList ? inPatchList.new : 0;
				}
				
				if (usedHref) {
					const usedCount = usedHref.querySelector(".nm-vtp__link-count");
					usedCount.innerHTML = inPatchList ? inPatchList.used : 0;
				}
			});
		}
	}

	/**
	 * gets the urls for the api from the element
	 * @returns {{newCarsUrl: string | undefined; usedCarsUrl: string | undefined;}}
	 */
	_getUrls() {
		const newCarsUrl =
			this.dataset.newcarurl && this.dataset.newcarurl.length > 0
				? this.dataset.newcarurl
				: undefined;
		const usedCarsUrl =
			this.dataset.usedcarurl && this.dataset.usedcarurl.length > 0
				? this.dataset.usedcarurl
				: undefined;
		return {
			newCarsUrl,
			usedCarsUrl,
		};
	}

	/**
	 * Starts patching process
	 */
	async _initalizePartnerPatch() {
		const partnerId = this.kvpsSyncId;
		const { newCarsUrl, usedCarsUrl } = this._getUrls();
		if (partnerId && newCarsUrl && usedCarsUrl) {
			const listOfCars = await this._fetchData({
				partnerId,
				newCarsUrl,
				usedCarsUrl,
			});
			if (listOfCars) {
				this._patch(listOfCars);
			}
		}
	}

	/**
	 * initialize elements
	 * @returns {void}
	 */
	_initializeModule() {
		const flyout = this.querySelector(".audi-dropdown-flyout");

		if (!flyout) {
			return;
		}

		this.dropdown = flyout;

		const selectedData = this.dropdown.getSelectedItemData();
		if (selectedData && selectedData.id) {
			this.currentModelId = selectedData.id;
		}

		this.handleDropdownSelectionChange = this.handleDropdownSelectionChange.bind(
			this
		);

		this.dropdown.subscribeToFlyout(this.handleDropdownSelectionChange);
	}

	/**
	 * reacts to selection change in dropdown
	 * @param {Object} data_ - data of selected flyout item
	 * @returns {void}
	 */
	handleDropdownSelectionChange(data_) {
		if (!data_ || !data_.id) {
			return;
		}

		if (this.currentModelId) {
			this._setModelLinksAndEecVisibilityById(this.currentModelId, false);
		}

		this._setModelLinksAndEecVisibilityById(data_.id, true);
	}

	/**
	 * showModelContent
	 * @param {string} modelId - Id of the selected Model
	 * @param {boolean} showContainer_ - whether to show or hide the container
	 * @returns {void}
	 */
	_setModelLinksAndEecVisibilityById(modelId_, showContainer_) {
		if (!modelId_) {
			return;
		}

		const classActive = "nm-vtp--active";

		const linkContainer = this.querySelector(
			".nm-vtp__link-container[data-modelid='" + modelId_ + "']"
		);
		const eecContainer = this.querySelector(
			".nm-vtp__eec-container[data-modelid='" + modelId_ + "']"
		);

		if (showContainer_) {
			linkContainer.classList.add(classActive);
			eecContainer.classList.add(classActive);
			this.currentModelId = modelId_;
		} else {
			linkContainer.classList.remove(classActive);
			eecContainer.classList.remove(classActive);
		}
	}
}

if (window.customElements.get("audi-vtp") === undefined) {
	window.customElements.define("audi-vtp", VTP);
}
